import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import Banner from "../components/Banner"
import ContactWrapper from "../components/ContactWrapper"
import BannerTop from "../components/Modules/MediaPush/BannerTop"


const IndexPage = () => (
  <Layout>
    <Seo title="Contacto" />
    <BannerTop page="contacto"/>
    <Banner
      section="contacto" 
      title="CONTACTANOS"
      subtitle="Un equipo multidisciplinario está esperando tu consulta para brindarte la mejor atención."/>
    <ContactWrapper/>
  </Layout>
)

export default IndexPage
